@media screen and (max-width: 2560px){
#navButton{
  font-size: 30px;
}
#goto{
  max-height: 45px;
}
#airDroid{
  max-height: 45px;
}
#pramsLogo{
  max-height: 75px;
}
}

@media screen and (max-width: 1440px){
#navButton{
  font-size: 30px;
}
#goto{
  max-height: 45px;
  margin-bottom: 10px;
}
#airDroid{
  max-height: 45px;
  margin-bottom: 10px;
}

}

@media screen and (max-width: 1200px){
#navButton{
  font-size: 22px;
}
#goto{
  max-height: 35px;
}
#airDroid{
  max-height: 35px;
}
#pramsLogo{
  max-height: 65px;
}

}
@media screen and (max-width: 1024px){
#navButton{
  font-size: 22px;
}
#goto{
  max-height: 35px;
}
#airDroid{
  max-height: 35px;
}
#pramsLogo{
  max-height: 65px;
}

}

@media screen and (max-width: 992px){
#navButton{
  font-size: 30px;
}
#goto{
  max-height: 45px;
}
#airDroid{
  max-height: 45px;
}
}

@media screen and (max-width: 768px){
  #navButton{
    font-size: 30px;
  }
  #goto{
    max-height: 50px;
  }
  #airDroid{
    max-height: 50px;
  }

}
@media screen and (max-width: 425px){
  #navButton{
    font-size: 25px;
  }
  #pramsLogo{
    max-height: 55px;
  }
  #goto{
    max-height: 40px;
  }
  #airDroid{
    max-height: 40px;
  }
}
@media screen and (max-width: 375px){

  #pramsLogo{
    max-height: 45px;
  }
  #goto{
    max-height: 35px;
  }
  #airDroid{
    max-height: 35px;
  }
}
@media screen and (max-width: 300px){
  #navButton{
    font-size: 23px;
  }

  #pramsLogo{
    max-height: 30px;
  }
  #goto{
    max-height: 30px;
  }
  #airDroid{
    max-height: 30px;
  }
}

#navButton{
  font-weight: bold;
  color: var(--pramsBlue);
}
#navButton:hover{
  color: var(--pramsWhite);
}