/* body, html {
  height: 100%;
} */

@media screen and (max-width: 2560px){
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 425px){
  #innovate-col-1,#innovate-col-2,#innovate-col-3{
    min-width: 250px;
  }
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
    margin-left: -23px;
    margin-right: 0;
    margin-bottom: 15px;
  }
  #the_prams_way_logo{
    max-width: 250px;
  }
}


#card-holder-blue {
  background-color: rgb(2, 46, 105);
  color: #fff;
  border: 0;
  border-radius: 30px;
  box-shadow: 0px 5px 2px 0px black;

}
#card-holder-blue-2 {
  background-color: rgb(2, 46, 105);
  color: #fff;
  border: 0;
  border-radius: 30px;
  box-shadow: 0px 5px 2px black;

}
#card-holder-white {
  background-color: #fff;
  color: black;
  border: 0;
  border-radius: 30px;
}
#card-holder-footer {
  background-color: var(--pramsBlue);
  color: white;
  border: 0;
  border-radius: 30px;
  box-shadow: 0px -5px 2px  black;
}


.header-white {
  color: black;
  background-color: #fff;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 10px;
  padding-bottom: 10px;
  border-radius: 30px;
  font-weight: 700;
}

.home-background {
  background-image: url(../../public/pipe_blue.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}


.parallax {
  /* The image used */
  background-image: url(../../public/pipe_blue.jpg);

  /* Full height */
  height: 65%; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: 5px solid rgb(2, 46, 105);
  border-radius: 15px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
  border-radius: 15px;
}

.flip-card-back {
  background-color: rgb(2, 46, 105);
  color: white;
  transform: rotateY(180deg);
}

.card-img-cont {
  height: 250px;
  width: 290px;

}
.card-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.cardTitle {
  background-color: rgb(2, 46, 105);
  color: white;
  font-size:x-large;
  padding-bottom: 5px;
  font-weight: bolder;
}

.textCont {
  padding: 10px;
}