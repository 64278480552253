.parallax_404 {
  /* The image used */
  background-image: url(../../public/404_image.jpeg);

  /* Full height */
  height: 100%; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}