#downloads-header {
  color:rgb(2, 46, 105);
}

#downloads-card {
  margin: 0px 30px 0px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

.btn-slide,
.btn-slide2 {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 200px;
  line-height: 50px;
  padding: 0;
  border-radius: 50px;
  background: #fdfdfd;
  border: 3px solid rgb(2, 46, 105);
  /* margin-left: 70px; */
  /* margin-bottom: 10px; */
  transition: 0.5s;
}

.btn-slide2 {
  border: 3px solid rgb(2, 46, 105);
}

.btn-slide:hover {
  background-color: rgb(2, 46, 105);
}

.btn-slide2:hover {
  background-color: rgb(2, 46, 105);
}

.btn-slide:hover span.circle,
.btn-slide2:hover span.circle2 {
  left: 100%;
  margin-left: -45px;
  /* margin-top: 5px; */
  background-color: #fdfdfd;
  color: rgb(2, 46, 105);
}

.btn-slide2:hover span.circle2 {
  color: rgb(2, 46, 105);
}

.btn-slide:hover span.title,
.btn-slide2:hover span.title2 {
  left: 40px;
  opacity: 0;
}

.btn-slide:hover span.title-hover,
.btn-slide2:hover span.title-hover2 {
  opacity: 1;
  left: 40px;
}

.btn-slide span.circle,
.btn-slide2 span.circle2 {
  display: block;
  background-color: rgb(2, 46, 105);
  color: #fff;
  position: absolute;
  float: left;
  margin: 2.5px;
  line-height: 42px;
  height: 40px;
  width: 40px;
  top: 0;
  left: 0;
  transition: 0.5s;
  border-radius: 50%;
  /* margin-top: 4px; */
}

.btn-slide2 span.circle2 {
  background-color: rgb(2, 46, 105);
}

.btn-slide span.title,
.btn-slide span.title-hover,
.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  position: absolute;
  left: 50px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  color: rgb(2, 46, 105);
  transition: 0.5s;
}

.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  color: rgb(2, 46, 105);
  left: 70px;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2 {
  left: 70px;
  opacity: 0;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2 {
  color: #fff;
}

.boldText {
  font-weight: bold;
}



/* .dlTitle {
  margin: auto;
  width: 520px;
  color: rgb(2, 46, 105);
  font-size: 40px;
  font-weight: bold;
} */

/* #dlCont {
  margin-bottom: 20px;
}

#dlTitleText{
  font-weight: 500;
} */


/* @media screen and (max-width: 1400px){
  #header-col{
    max-width: 500px;
  }
}
@media screen and (max-width: 1200px){
  #header-col{
    max-width: 1200px;
  }
}
@media screen and (max-width: 992px){
  #header-col{
    max-width: 992px;
  }
}
@media screen and (max-width: 768px){
  #header-col{
    max-width: 768px;
  }
}
@media screen and (max-width: 576px){
  #header-col{
    max-width: 768px;
  }
} */