.parallax_solutions_first {
  /* The image used */
  background-image: url(../../public/up_blue.jpg);

  /* Full height */
  height: 75%; 
  width: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax_solutions_last {
  /* The image used */
  background-image: url(../../public/up_blue.jpg);

  /* Full height */
  height: 30%; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax_solutions_two {
  /* The image used */
  background-image: url(../../public/pad_blue.jpg);

  /* Full height */
  height: 30%; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax_solutions_three {
  /* The image used */
  background-image: url(../../public/pipeline_blue.jpg);

  /* Full height */
  height: 30%; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax_solutions_four {
  /* The image used */
  background-image: url(../../public/off_blue_2.jpeg);

  /* Full height */
  height: 30%; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax_solutions_five {
  /* The image used */
  background-image: url(../../public/flow_blue.jpg);

  /* Full height */
  height: 30%; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#solutions_pic_1 {
  width: 100%;
  max-width: 650px;
  min-width: 250px;
  border-radius: 45px;
  box-shadow: -5px 5px 5px 0px black;
}
#solutions_pic_2 {
  /* margin:  0 auto; */
  width: 100%;
  max-width: 729px;
  border-radius: 45px;
  box-shadow: 5px 5px 5px 5px black;
}
#solutions_pic_3 {
  width: 100%;
  max-width: 600px;
  min-width: 250px;
  border-radius: 45px;
  box-shadow: -5px 5px 5px 5px black;
  margin-bottom: 25px;
}
#solutions_pic_4 {
  width: 100%;
  max-width: 600px;
  min-width: 250px;
  border-radius: 45px;
  box-shadow: -5px 5px 5px 0px black;
  margin-top: 15px;
}
#card-holder-white-solutions {
  background-color: white;
  border-radius: 40px;
  box-shadow: 0px 0px 10px 20px black, inset 0px 0px 1px 6px black;
  border: 0;
}
#card-holder-blue-solutions {
  background-color: var(--pramsBlue);
  color:var(--pramsWhite);
  border-radius: 40px;
  box-shadow: 0px 0px 15px 15px black, inset 0px 0px 1px 6px black;
  border: 0;
}

#card-holder-footer-solutions {
  background-color: var(--pramsBlue);
  color: white;
  border: 0;
  border-radius: 30px;
  box-shadow: 0px 0px 15px 15px black, inset 0px 0px 1px 6px black;
}

/* p {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  color: #000000;
  text-align: center;
} */