:root {
  --pramsBlue: #002F65;
  --pramsBlack: #000000;
  --pramsGrey: #e9e9e9;
  --pramsWhite: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'pramsFontRegular';
  src: local('pramsFontRegular'), 
    url('/src/fonts/Stone_Sans_Regular.ttf') format('truetype')
}
@font-face {
  font-family: 'pramsFontBold';
  src: local('pramsFontBold'), 
    url('/src/fonts/Stone_Sans_Bold.ttf') format('truetype')
}
